<template>
  <div class="d-flex justify-content-between">
    <div class="p-4 mt-5 flex-start">
      <div class="h5" >{{ $route.path }} </div>

      <router-link
      v-if="$route.name != 'Home_view'"
       :to="{
        path: this.getPreviousPath()
      
      }" class="returnBackArrow">
        <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Volver
      </router-link>
    </div>

    <!-- empresa -->
    <div class="mt-5 me-5 flex-end">
      <router-link :to="{ name: 'Empresa_view' }" class="alt-logo">
        <img
          :src="$store.state.init.company.image_url"
          :alt="'Logo ' + $store.state.init.company.company_name"
          class="img-logo"
        />
      </router-link>
      <div class="text-end">
        {{ $store.state.init.company.company_name }} <br />
        NIF: {{ $store.state.init.company.company_nif }}
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "Nav_view_component",
  props: {
    title: {
      default: false,
      required: false
    },
  },
  methods: {
    getPreviousPath() {
      // a partir de un path por ejemplo /invoices/89 que el previous sea /invoices
      const path = this.$route.path;
      const pathArray = path.split('/');
      pathArray.pop();
      return pathArray.join('/');

    }
  }
};
</script>

<style>
.img-logo {
  max-width: 120px;
}

.alt-logo{
    text-decoration: none;
    color: var(--letras-gris);
}

</style>