<template>
  <div class="mb-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="fs-2">Detalles</div>
      <div class="dropdown-wrapper">
        <button class="btn btn-secondary dropdownMenuIcon" @click.stop="toggleDropdown">
          <font-awesome-icon :icon="['fas', 'chevron-down']" />
        </button>
        <div v-if="showDropdown" class="dropdownMenu" ref="dropdownMenu">
          <ul class="list-none">
            <li @click="downloadPDF"> <font-awesome-icon :icon="['fas', 'download']" /> Descargar PDF</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-12 d-flex justify-content-between align-items-center mt-1">
        <div class="fw-bold">Tipo:</div>
        <div>
          <Type_component :data="invoice.type"></Type_component>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-between align-items-center mt-1">
        <div class="fw-bold">Número:</div>
        <b>{{ invoice.invoice_num }}</b>
      </div>
      
      <div class="col-12 d-flex justify-content-between align-items-center mt-1">
        <div class="fw-bold">Fecha emisión:</div>
        <div>{{ invoice.emission_date }}</div>
      </div>
      <div v-if="invoice.expiration_date" class="col-12 d-flex justify-content-between align-items-center mt-1">
        <div class="fw-bold">Fecha vencimiento:</div>
        <div>{{ invoice.expiration_date ? invoice.expiration_date : 'N/A' }}</div>
      </div>
      <div v-if="false" class="col-12 d-flex justify-content-between align-items-center mt-1">
        <div class="fw-bold">Estado:</div>
        <div>
          <Status_component :data="invoice.status"></Status_component>
        </div>
      </div>
      <div v-if="false" class="col-12 d-flex justify-content-between align-items-center mt-1">
        <div class="fw-bold">Pago pendiente:</div>
        <div>
          <div v-if="invoice.integer_total_price - invoice.integer_amount_paid > 0">
            {{ (invoice.integer_total_price - invoice.integer_amount_paid) / 100 }} €
          </div>
          <div v-else>
            0 €
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-baseline justify-content-end">
      <div class="fs-5 pe-2">
        Importe:
      </div>
      <div class="fs-3">
        {{ invoice.total_price }} €
      </div>
    </div>
  </div>
</template>


<script>
import Status_component from "@/components/Invoice_components/Status_component.vue";
import Type_component from "@/components/Invoice_components/Type_component.vue";

export default {
  name: 'InvoiceInfo',
  data() {
    return {
      showDropdown: false // Cambié a false para ocultar el dropdown inicialmente
    }
  },
  components: {
    Type_component,
    Status_component
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    downloadDocument(url, filename) {
      // Crear un enlace temporal
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      // Añadir el enlace al documento y simular un clic
      document.body.appendChild(link);
      link.click();

      // Remover el enlace del documento
      document.body.removeChild(link);
    },
    handleOutsideClick(event) {
      if (this.showDropdown && this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    async downloadPDF() {
      const token = this.$store.state.authToken; // Assuming you are using Vuex for state management
      const invoiceId = this.invoice.id;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/portal/invoices/${invoiceId}/pdf?token=${token}`)
        .then(response => {
          if(response.data.rc == 1) {
            const url = response.data.data.url;
            // abrir en una nueva pestaña
          window.open(url, '_blank');
          }
          
          
            
          else
            this.error = true
        })
        .catch(error => {
          this.error = true
          console.error("Failed to load PDF:", error);
        })
        .finally(() => {
          this.loading = false;
          this.showDropdown = false;
        });
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }
}
</script>

<style scoped>
.dropdown-wrapper {
  position: relative;
}
.dropdownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  z-index: 1000;
  min-width: 160px;
}
.dropdownMenuIcon {
  font-size: 0.5rem;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}
.list-none {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdownMenu ul > li {
  padding: 10px;
  cursor: pointer;
}
</style>
