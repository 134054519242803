<template>
  <div class="container mt-5">
    <div class="d-flex flex-column align-items-center justify-content-center mb-5">
      <h1>Bienvenido a InfinitySync</h1>
    </div>
    <div class="row">
      <!-- Card 1: Facturas Totales -->
      <div class="col-md-4 mb-4">
        <div class="card text-center">
          <div class="card-body">
            <div class="card-title d-flex justify-content-center align-items-center">
              <i class="bi bi-receipt display-4 me-2"></i>
              <h5>Facturas Totales</h5>
            </div>
            <p class="card-text display-4">123</p>
          </div>
        </div>
      </div>
      <!-- Card 2: Facturas Pagadas -->
      <div class="col-md-4 mb-4">
        <div class="card text-center">
          <div class="card-body">
            <div class="card-title d-flex justify-content-center align-items-center">
              <i class="bi bi-check-circle display-4 me-2"></i>
              <h5>Facturas Pagadas</h5>
            </div>
            <p class="card-text display-4">100</p>
          </div>
        </div>
      </div>
      <!-- Card 3: Facturas Pendientes -->
      <div class="col-md-4 mb-4">
        <div class="card text-center">
          <div class="card-body">
            <div class="card-title d-flex justify-content-center align-items-center">
              <i class="bi bi-exclamation-circle display-4 me-2"></i>
              <h5>Facturas Pendientes</h5>
            </div>
            <p class="card-text display-4">23</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Card 4: Monto Total Facturado -->
      <div class="col-md-4 mb-4">
        <div class="card text-center">
          <div class="card-body">
            <div class="card-title d-flex justify-content-center align-items-center">
              <font-awesome-icon icon="dollar-sign" class="display-4 me-2" />
              <h5>Monto Total Facturado</h5>
            </div>
            <p class="card-text display-4">$50,000</p>
          </div>
        </div>
      </div>
      <!-- Card 5: Monto Pagado -->
      <div class="col-md-4 mb-4">
        <div class="card text-center">
          <div class="card-body">
            <div class="card-title d-flex justify-content-center align-items-center">
              <font-awesome-icon icon="money-check-alt" class="display-4 me-2" />
              <h5>Monto Pagado</h5>
            </div>
            <p class="card-text display-4">$40,000</p>
          </div>
        </div>
      </div>
      <!-- Card 6: Monto Pendiente -->
      <div class="col-md-4 mb-4">
        <div class="card text-center">
          <div class="card-body">
            <div class="card-title d-flex justify-content-center align-items-center">
              <font-awesome-icon icon="exclamation-triangle" class="display-4 me-2" />
              <h5>Monto Pendiente</h5>
            </div>
            <p class="card-text display-4">$10,000</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home_view',
  components: {
  }
};
</script>

<style>
/* Estilos opcionales para el layout */
</style>
