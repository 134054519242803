<template>
  <div class="d-flex flex-column justify-content-center">
    <h2 class="text-center mb-4">Lista de Facturas</h2>
    <Loading_table v-if="loading" />
    <div v-else>
      <div v-if="invoices.data.length > 0">
        <table class="table table-responsive table-hover">
          <thead class="">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Tipo</th>
              <th scope="col">Fecha</th>
              <!-- <th scope="col">Descripción</th> -->
              <th scope="col">Total</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in paginatedInvoices" :key="invoice.id">
              <SingularInvoice_component :invoice="invoice" />
            </tr>
          </tbody>
        </table>

        <!-- Paginación de Bootstrap -->
        <nav aria-label="Page navigation paginationComponent">
          <ul class="pagination">
            <li class="page-item" :class="{ disabled: !invoices.prev_page_url }">
              <a class="page-link" href="#" @click.prevent="gotoPage(1)" aria-label="First">
                <span aria-hidden="true">&laquo;&laquo;</span>
              </a>
            </li>
            <li class="page-item" :class="{ disabled: !invoices.prev_page_url }">
              <a class="page-link" href="#" @click.prevent="gotoPage(invoices.current_page - 1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li
              v-for="page in paginationPages"
              :key="page"
              class="page-item"
              :class="{ active: page === invoices.current_page }"
            >
              <a class="page-link" href="#" @click.prevent="gotoPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: !invoices.next_page_url }">
              <a class="page-link" href="#" @click.prevent="gotoPage(invoices.current_page + 1)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
            <li class="page-item" :class="{ disabled: !invoices.next_page_url }">
              <a class="page-link" href="#" @click.prevent="gotoPage(invoices.last_page)" aria-label="Last">
                <span aria-hidden="true">&raquo;&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div v-else class="text-center">
        <h3>No hay facturas</h3>
      </div>
    </div>
  </div>
</template>


<script>
import SingularInvoice_component from "@/components/Invoice_components/SingularTableInvoice_component.vue";
import Loading_table from "@/components/Loading_table.vue";

export default {
  name: "InvoiceTable",
  components: {
    SingularInvoice_component,
    Loading_table,
  },
  data() {
    return {
      loading: true,
      invoices: {
        data: [],
        current_page: 1,
        last_page: 1,
        total: 0,
      },
      page: 1,
      pageRange: 2 // Número de páginas a mostrar a cada lado de la página actual
    };
  },
  mounted() {
    this.loadInvoiceData();
  },
  computed: {
    paginatedInvoices() {
      return this.invoices.data;
    },
    paginationPages() {
      let start = Math.max(this.invoices.current_page - this.pageRange, 1);
      let end = Math.min(this.invoices.current_page + this.pageRange, this.invoices.last_page);
      
      if (this.invoices.current_page <= this.pageRange) {
        end = Math.min(2 * this.pageRange + 1, this.invoices.last_page);
      }
      
      if (this.invoices.current_page + this.pageRange >= this.invoices.last_page) {
        start = Math.max(this.invoices.last_page - 2 * this.pageRange, 1);
      }
      
      let pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    loadInvoiceData() {
      this.loading = true;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/portal/invoices`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authToken}`,
          },
          params: {
            site_id: 1,
            page: this.page,
          },
        })
        .then((response) => {
          this.invoices = response.data.data;
          this.page = this.invoices.current_page;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    gotoPage(page) {
      if (page < 1 || page > this.invoices.last_page) {
        return;
      }
      this.page = page;
      this.loadInvoiceData();
    },
  },
};
</script>

<style>
.table td,
.table th {
  vertical-align: middle; /* Alineación vertical al centro para todas las celdas de la tabla */
}
.page-link {
  font-size: 0.8rem;
}

.table,
.table thead,
.table tbody,
.table tr,
.table th,
.table td {
  background-color: var(----azul-body);
}
</style>


