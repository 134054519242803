
<template>
    <Nav_view_component />
    <router-view class="p-xs-1 p-4 "/>
</template>

<script>
import Nav_view_component from "@/components/Nav_view_component.vue";

export default {
  name: 'Portal_view',
  components: {
    Nav_view_component
  }
};
</script>

<style>
/* Estilos opcionales para el layout */
</style>
