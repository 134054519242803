<template>
  <div class="p-3 cardProfile">
    <div class="text-center h4">Facturas</div>
    <div>
      Texto
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceList_component",
};
</script>

<style>
</style>
