<template>
    <!-- logo encima del formulario -->
    <div class="d-flex text-center flex-row align-items-center justify-content-center mb-4">
        <Loading_component v-if="loading"></Loading_component>
        <div v-else class="">
            <img :src="logo" alt="" class="logoCompany">
        </div>

       
    </div>
    <!-- FIN logo encima del formulario -->
</template>

<script>
export default {
    name: 'SideMenu',
    data() {
        return {
            loading: true,
            // Definir las rutas de las imágenes
            logo: false,

        }
    },
    mounted() {
        this.getLogo();
        console.log('Logo_Form.vue mounted');
        //get param from url
        // console.log(this.$route.params.id);
    },
    methods: {
        getLogo() {
            this.$axios
            .get(`${process.env.VUE_APP_API_BASE_URL}/api/${this.$route.params.site_id}/image`)
            .then((response) => {
                this.logo = response.data.data.image;
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoCompany {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}
</style>