<template>
    <div id="app-main">
        <!-- {{ this.$store.state.init }} -->

      <SideMenu_component v-if="$store.state.init.auth" />
      <Side_Blue class="col-md-5" v-if="!$store.state.init.auth && !$store.state.loading"> </Side_Blue>
      <Loading_component v-if="$store.state.loading" :fullScreen="true" />
      <main v-else class="main-content">
        <router-view
          v-if="!this.$store.state.loading && !this.$store.state.error.status"
         
        />
        <div
          v-if="this.$store.state.error.status"
          class="alert alert-danger mt-2"
          role="alert"
        >
          {{ this.$store.state.error.message }}
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import SideMenu_component from "@/components/SideMenu_component.vue";
  import Side_Blue from "@/components/auth/Side_Blue.vue";
  
  import "@/assets/css/general_styles.css";
  import cookie from "cookiejs";
  
  export default {
    name: "App",
    components: {
      SideMenu_component,
      Side_Blue,
    },
    mounted() {
      this.initApp(); //cuando se cree la pagina, haz esto
    },
  
    methods: {
      // inicialización de la app
      async initApp() {
        this.ifForcenLoginRemovePreviousCookie()
        
        // obtenemos token
        const token = this.getTokenFromCookie();
        // si no hay token, redirigir a login
        if (token === false) {
          this.goToLoginOrSimilar();
          return;
        }
  
        // si es invalido redirigir a login
        const isValid = await this.tokenIsValid(token);
        console.log('isValid', isValid, token)
        if (!isValid) {
          this.goToLoginOrSimilar();
          return;
        }
        // si llegamos hasta aqui todo es correcto
        this.loadAppLogged(token);
      },
      // get token from cookie
      getTokenFromCookie() {
        return cookie.get("authToken") ?? false;
      },
      // validate Token
      async tokenIsValid(token) {
        try {
          const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data.rc == 1;
        } catch (error) {
          console.log('error', error);
          return false;
        }
      },
      // si la ruta en su path no contiene "login" entonces redirigir a login
      goToLoginOrSimilar() {
        //cambias la variable a false
        this.$store.state.loading = false;
        const path = window.location.pathname
        console.log('window.location.pathname', path, path.includes("login"))
        if (!window.location.pathname.includes("login")) {
          console.log("redirigiendo a login");
          this.$router.push({ name: "Login_view" });
        }
      },
      async loadAppLogged(token) {
        // guardamos el token en store
        this.$store.state.authToken = token;
  
        try {
          const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/portal/init`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.authToken}`,
            },
          });
          if (response.data.rc == 1) {
            this.addDataFromResponseInit(response.data.data);
          } else {
            this.$store.state.error.status = true;
            this.$store.state.error.message = "Error al cargar la app. Inténtalo más tarde";
          }
        } catch (error) {
          this.$store.state.error.status = true;
          this.$store.state.error.message = "Error al cargar la app. Inténtalo más tarde";
        } finally {
            if (window.location.pathname.includes("login"))
                this.$router.push({ name: "Invoices_view" });
          this.$store.state.loading = false;
        }
      },

      ifForcenLoginRemovePreviousCookie() {
        var stri = window.location+"";

        // si por api nos da un token y se trata de una llamada de login entonces borramos la cookie actual
        if (stri.includes("token") && window.location.pathname.includes("login")) {
          cookie.remove("authToken");
        } 
      },
  
      handleActualizarApp() {
        window.location.reload();
      },
      addDataFromResponseInit(data) {
        this.$store.state.init = data;
        
        //redirigimos a /portal si estamos en alguna ruta de login
        
      }
    },
  };
  </script>
  
  <style>
  /* ------------------------ */
  .sideMenu_Size {
    width: 25%;
    max-width: 230px;
  }
  
  .sticky-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
  }
  
  .altura {
    height: 100vh;
  }
  
  /* ------------------------- */
  </style>
  