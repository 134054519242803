<template>
  <div class="lateral ">
    <div class="">
      <div class="position-fixed lateral fondo-azul2 p-3">
        <!-- Contenido principal -->
        <div>
          <!-- logo -->
            <!-- logo arriba en cao user -->
            <BottomLogo v-if="$store.state.init.role == 'user'"  />
            <!-- Logo abajo en caso contact -->
            <BottomLogo v-if="$store.state.init.role == 'contact'" showLegal class="logoInBottom"/>
            <!-- logo empresa -->
            <router-link :to="{ name: 'Empresa_view' }" v-if="$store.state.init.role == 'contact' && $store.state.init.company"  class="company-logo-and-name non-linked">
                <img
                :src="$store.state.init.company.image_url"
                :alt="'Logo ' + $store.state.init.company.company_name"
                class="img-logo m-auto w-100"
              />
              <span class="w-100 m-auto ">{{ $store.state.init.company.company_name }}</span>
          </router-link>
          <div class="align-items-center">
            
            <div class="w-100 element-of-menu">
              <div>Hola {{ $store.state.init.auth.name }} 👋</div>
              <small v-if="$store.state.init.role == 'contact'" class="muted">Contacto</small>
              <small v-if="$store.state.init.role == 'user'" class="muted">Usuario</small>

            </div>
          </div>
          <!-- <button @click="$store.state.init.role = 'contact'">change</button> -->

          <hr class="hr_Menu " />
          <div class="d-flex flex-column listadoMenu">

            <router-link  :to="{ name: 'Home_view'}" class="whiteLinks element-of-menu" >
              <i class="bi bi-house me-1"></i>      Inicio       
            </router-link>
            <router-link  :to="{ name: 'Invoices_view'}" class="whiteLinks element-of-menu" >
              <i class="bi bi-journal-richtext me-1"></i>Facturas
            </router-link>

            <router-link v-if="$store.state.init.role == 'user' && false" :to="{ name: 'Profile_view' }" class="whiteLinks element-of-menu">
              <i class="bi bi-person me-1"></i>Mi perfil
            </router-link>

            <router-link v-if="$store.state.init.role == 'contact' && false" :to="{ name: 'Profile_view' }" class="whiteLinks element-of-menu">
              <i class="bi bi-building me-1"></i>Mi información
            </router-link>

            <hr class="hr_Menu" />

            <Logout_component class="whiteLinks element-of-menu"></Logout_component>
          </div>
          <!-- flex column -->
        </div>

        <!-- div sin nada -->
      </div>
      <!-- div position fix -->
    </div>
    <!-- div "" -->


  </div>
</template>

<script>
import Logout_component from "@/components/Logout_component.vue";
import BottomLogo from "@/components/bottom_logo.vue";

export default {
  name: "SideMenu",
  data() {
    return {
      bottom_Logo: require("@/assets/logos/transparente.png"),

      // Definir las rutas de las imágenes
      logo: require("@/assets/logos/white.png"),
    };
  },
  components: {
    Logout_component,
    BottomLogo
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.lateral {
  width: var(--menu-width);
  height: 100vh;
  min-width: var(--menu-width);
  /* padding: var(--padding-menu); */
}
.whiteLinks  {
  color: var(--azul-oscuro);
  text-decoration: none;
  transition: 0.5s;
}

.whiteLinks :hover {
  /* transform: scale(1.2); */
  transform: translateX(5px);
  transition: 0.2s;
}

.hr_Menu {
  border-color: black;

  margin-bottom: 27px;
}
.element-of-menu {
  margin-top: 5px;
  padding: 3px 10px;

}

.logoInBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--menu-width);
}
.company-logo-and-name {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 20px;  
  padding-bottom: 20px;
}

</style>