<template>
    <div class="loginViews">
        <Logo_Form></Logo_Form>
        <div class="">
            <div class="text-muted fs-5 mb-3 text-center">
                Escribe código de verificación recibido a
                <span v-if="email">{{ email }}</span>
            </div>
            <form @submit.prevent="handleSubmit" class="mb-2">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Código" aria-label="Código" aria-describedby="basic-addon1" v-model="formulario.code" required>
                </div>
                <input type="submit" value="Enviar" class="btn btn-primary w-100">
            </form>
            <router-link :to="{ name: 'Login_view' }" class="returnBackArrow">
                <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Volver
            </router-link>
        </div>
    </div>
</template>

<script>
import Logo_Form from '@/components/auth/Logo_Form.vue';

export default {
    name: 'Email_code_view',
    components: {
        Logo_Form
    },
    data() {
        return {
            formulario: {
                code: '',
            }
        };
    },
    computed: {
        // Acceder al estado del email desde Vuex
        email() {
            return this.$store.state.login.email; // Asegúrate de usar el nombre correcto del estado en Vuex
        },
    },
    methods: {
        handleSubmit() {
            console.log('Comprobar código');
            // Aquí puedes manejar la lógica para verificar el código recibido
        }
    }
};
</script>

<style>

</style>
