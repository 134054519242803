// src/main.js
import { createApp } from 'vue'
import App from '@/App.vue'

//usar bootstrap
import 'bootstrap/dist/css/bootstrap.css' // el normal
import 'bootstrap-icons/font/bootstrap-icons.css' // los iconos
//fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
// axios
import axios from 'axios'
// componentes globales
import Loading_component from "@/components/Loading_component.vue";


// Agregar todos los iconos sólidos y de marcas a la biblioteca
library.add(fas, fab, far)
// Configura Axios para incluir el token de autenticación en los headers
axios.interceptors.request.use(config => {
    const token = store.state.authToken; // Asegúrate de que esta es la ruta correcta a tu token  

    if (token) {
        config.headers.Authorization = `Bearer ${store.state.authToken}`;

    
    }
    return config;
});

//importar router
import router from '@/router'

//importar store
import store from "@/store";

//crear la app
const appInstance = createApp(App);

//le decimos que use todo esto
appInstance.use(store);
appInstance.use(router);

appInstance.component('font-awesome-icon', FontAwesomeIcon)
appInstance.component('Loading_component', Loading_component);
//la version que queremos que muestre
appInstance.config.globalProperties.version = 'v1.0.0';
// Hacer Axios accesible globalmente
appInstance.config.globalProperties.$axios = axios



//entonces la creamos
appInstance.mount("#app");
