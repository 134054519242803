<template>
  <div class="p-5">
    <div class="card-empresa p-5">
      <div class="text-center mb-5">
        <img
          :src="$store.state.init.company.image_url"
          :alt="'Logo ' + $store.state.init.company.company_name"
          class="image-empresa"
        />
      </div>

      <table class="table">
        <tbody>
          <tr>
            <th scope="row">Nombre empresa:</th>
            <td>{{ $store.state.init.company.company_name }}</td>
          </tr>
          <tr>
            <th scope="row">NIF:</th>
            <td>{{ $store.state.init.company.company_nif }}</td>
          </tr>
          <tr>
            <th scope="row">Dirección:</th>
            <td>
              {{ $store.state.init.company.company_address }},
              {{ $store.state.init.company.company_state }},
              {{ $store.state.init.company.company_postal_code }}.
              {{ $store.state.init.company.company_country }}
            </td>
          </tr>
          <tr>
            <th scope="row">Contacto:</th>
            <td>{{ $store.state.init.company.company_phone }}</td>
          </tr>
          <tr>
            <th scope="row">Web:</th>
            <td>{{ $store.state.init.company.company_website }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Empresa_view",
  components: {},
};
</script>

<style scoped>
.image-empresa {
  max-width: 400px;
}

.card-empresa {
  max-width: 70%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 0 auto;
}
</style>