<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div id="banner">
      <div class="overlay"></div>
      <!-- Div de superposición -->
    </div>

    <div class="datosUsuario cardProfile">
      <div class="row align-items-center">
        <div class="col-md-2">
          <img :src="user_image" class="image-profile" />
        </div>
        <div class="col-md-10">
          <div class="fs-5">Nombre de persona real</div>
          <div class="typeLetra">Cliente</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderProfile_component",
  data() {
    return {
      // Definir las rutas de las imágenes
      user_image: require("@/assets/images/blank-user.jpg"),
      banner: require("@/assets/images/fondoUsuario.jpg"),
    };
  },
};
</script>

<style>

#banner {
  position: relative;
  width: 100%;
  height: 250px;
    border-radius: 15px;
  background-image: url("@/assets/images/fondoUsuario.jpg");
  background-position: center center;
  background-size: cover; /* Asegura que la imagen de fondo se ajuste bien al contenedor */
}




.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-azul);
  border-radius: 15px;
}

.image-profile {
  border-radius: 15px;
  max-width: 80%;
  height: auto;
}

.datosUsuario {
  width: 50%;
  margin-top: -50px;
  padding: 15px;

  background-color: hsla(0, 0%, 100%, 0.8) !important;
}

.typeLetra {
  font-size: 0.9rem;
}
</style>
