<template>
  <div class="loginViews">
    <Logo_Form v-if="false"></Logo_Form>
    <Logo_Company/>

    <div class="alert alert-info">
      No disponible el acceso en esta versión
    </div>
    <div  style="opacity: 0.4; " class="" :disabled="true">

      <router-link
        :to="{ name: 'Send_Email_view' }"
        class="border link rounded-2 p-2 d-flex align-items-center justify-content-center"
      >
        <font-awesome-icon
          :icon="['far', 'envelope']"
          class="pe-3 icono-email"
        />
        <div class="ps-2 letras">Entrar a través de código único</div>
      </router-link>

      <div class="text-muted mt-4 mb-2 ms-3">
        O inicia sesión con contraseña
      </div>

      <form @submit.prevent="handleSubmit">
        <div class="mb-3">
          <input
            type="email"
            class="form-control letras"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="basic-addon1"
            v-model="this.$store.state.login.email"
            required
          />
          <!-- cuando haya un cambio que se guarde en la store -->
        </div>
        <div class="mb-3">
          <input
            type="password"
            class="form-control letras"
            placeholder="Contraseña"
            aria-label="Contraseña"
            aria-describedby="basic-addon1"
            v-model="formulario_login.password"
            required
          />
        </div>

        <button type="submit" class="btn btn-primary w-100">
          <Loading_component v-if="loading" :width="25"></Loading_component>
          <text v-else>Login</text>
        </button>
      </form>

      <div
        v-if="this.error != null"
        class="alert alert-danger mt-2"
        role="alert"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
//componentes externos
import Logo_Form from "@/components/auth/Logo_Form.vue"; 
import Logo_Company from "@/components/auth/Logo_Company.vue"; 

import cookie from "cookiejs";

export default {
  name: "Login_view",
  components: {
    Logo_Form,
    Logo_Company
  },

  data() {
    return {
      // Las variables que están ligadas a los inputs
      formulario_login: {
        email: this.$store.state.login.email,
        password: "",
      },
      error: null,
      loading: false,
    };
  },
  mounted() {
    this.$store.state.showLogedPart = false;
  },
  methods: {
    handleSubmit() {

      // provisional para que no haga nada el usuario 
   



      //quitar error
      this.error = null;

      //si esta cargando no hacer la llamada
      if (this.loading) {
        return;
      }

      //poner true hasta que se haga la llamada (evitar spam)
      this.loading = true;

      //llama a a axios
      this.$axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/api/login`, {
          email: this.$store.state.login.email,
          password: this.formulario_login.password,
        })
        .then((response) => {
          console.log("patata" + response.data.data.token);

          // //guardar el token en la store
          this.$store.authToken = response.data.data.token;

      
          // //guardar el token enuna COOKIE
          cookie.set("authToken", response.data.data.token, {
            expires: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ),
          });

          // // //guardar el usuario
          this.$store.user = response.data.data;

          //cargar app.vue de nuevo
          window.location.reload();
        })
        .catch((error) => {
          console.log("ERROR:", error.response.data.message);
          this.error = error.response.data.message;
        })
        .finally(() => {
          //se acaba la carga no importa cual es el resultado
          this.loading = false;
        });

      //se comprobarian que las cosas del formulario estarian bien y tal. Existe en el sitio, la contra es correcta..

      //si no está bien se da un error
    },
  },
};
</script>

<style>
body {
  color: var(--letras-gris);
}

.altura {
  height: 100vh;
}

.link {
  text-decoration: none;
  color: var(--letras-gris);
}

.link:hover {
  color: var(--azul-fondo);
}

.icono-email {
  font-size: 1.5rem;
}

</style>