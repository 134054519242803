<template>
  <td>{{ invoice.invoice_num }}</td>
  <td>
    <Type_component :data="invoice.type"></Type_component>
  </td>
  <td>
    <span class="fw-bold">{{ invoice.emission_date }}</span>
  </td>
  <!-- <td>{{ invoice.desc }}</td> -->
  <td>
    <span class="fw-bold">{{ invoice.total_price }} €</span>
  </td>
  <td>
    <router-link
      :to="{ name: 'SingularInvoice_view', params: { invoice_id: invoice.id } }"
      class="btn btn-primary btn-sm"
    >
      Ver Detalles
    </router-link>
  </td>
</template>

<script>
import Type_component from "@/components/Invoice_components/Type_component.vue";

export default {
  name: "SingularTableInvoice",
  components: {
    Type_component,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
};
</script>
 