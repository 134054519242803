import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'; // Importa tu store Vuex


import Portal from '@/views/Portal_view.vue';

import Login_view from '@/views/Login_view.vue'
import Login_view_of_site from '@/views/Auth/Login_view_of_site.vue'
import Login_site_by_token from '@/views/Auth/LoginToSiteByToken.vue'
import Send_Email_view from '@/views/Send_Email_view.vue'
import Email_code_view from '@/views/Email_code_view.vue'
import SingularInvoice_view from '@/views/SingularInvoice_view.vue'
import Empresa_view from '@/views/Empresa_view.vue'


import Home_view from '@/views/Home_view.vue'
import InvoiceTable_component from '@/views/InvoiceTable_view.vue'
import Profile_view from '@/views/Profile_view.vue'


const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  // normal login
  {
    path: '/login',
    name: 'Login_view',
    component: Login_view
  },
  // normal login by email
  {
    path: '/login/email',
    name: 'Send_Email_view',
    component: Send_Email_view
  },
  // normal login by email verify
  {
    path: '/login/email/verify',
    name: 'Email_code_view',
    component: Email_code_view
  },
  // login of a specific site
  {
    path: '/:site_id/login',
    name: 'Login_view_of_site',
    component: Login_view_of_site
  },
  // login to specific site with secret token
  {
    path: '/:site_id/login_by_token/',
    name: 'Login_site_by_token',
    component: Login_site_by_token
  },
  {
    path: '/portal',
    component: Portal,
    children: [
      {
        name: "Home_view",
        path: '',
        component: Home_view,
        redirect: '/portal/invoices'

      },
      {
        name: "Invoices_view",
        path: 'invoices',
        component: InvoiceTable_component,
      },
      {
        path: 'invoices/:invoice_id',
        name: 'SingularInvoice_view',
        component: SingularInvoice_view
      },
      {
        path: 'profile',
        component: Profile_view,
        name: 'Profile_view',
      
      },
      {
        path: 'empresa',
        name: 'Empresa_view',
        component: Empresa_view
      }
    ]
  }
];




const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//MIDDLEWARES
router.beforeEach((to, from, next) => {
  // Verifica si la ruta comienza con '/portal'
  if (to.path.startsWith('/portal')) {
    // console.log('portal');
    store.commit('updateShowLogin', false);
    store.commit('updateShowLogged', true);
  }
  if (to.path.startsWith('/login')) {
    // console.log('login');
    store.commit('updateShowLogin', true);
    store.commit('updateShowLogged', false);
  }
  // console.log(to.path);



  // Continúa con la navegación
  next();
});

export default router
