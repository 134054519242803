<template>
  <Loading_component v-if="loading" :fullScreen="true" />

  <iframe
    v-else-if ="!error"
    :src="iframeSrc+'#toolbar=1'"
    frameborder="0"
    width="100%"
    class="iframeStyle"
  ></iframe>
  <data v-else-if="error" value="">
    <div  class="alert alert-danger" role="alert">
      Error al cargar el PDF
    </div>
  </data>
</template>

<script>
export default {
  name: "SingularInvoice",
  props: ["invoice"],
  data() {
    return {
      iframeSrc: '',
      loading: true,
      error: false

    };
  },
  mounted() {
    this.loadInvoicePDF();
  },
  methods: {
    
    loadInvoicePDF() {
      const token = this.$store.state.authToken; // Assuming you are using Vuex for state management
      const invoiceId = this.invoice.id;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/portal/invoices/${invoiceId}/pdf?token=${token}`)
        .then(response => {
          if(response.data.rc == 1)
            this.iframeSrc = response.data.data.url;
          else
            this.error = true
        })
        .catch(error => {
          this.error = true
          console.error("Failed to load PDF:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.iframeStyle {
  min-height: 400px;
  height: 80vh;
  max-height: 900px;
}
</style>
