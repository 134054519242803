<template>
  <div>
    <Loading_component v-if="loading" :fullScreen="true" />
    <div v-else class="row w-100  pt-5">
      <div class="col-xl-8 col-md-7 pe-5">
        <InvoicePDFViewer :invoice="invoice" />
      </div>
      <div class="col-xl-4 col-md-5">
        <div>
          <InvoiceInfo_component :invoice="invoice" />
          <MetodoPago_component v-if="false" :metodo_pago="metodo_pago" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoicePDFViewer from "@/components/Invoice_components/InvoicePDFViewer.vue";
import InvoiceInfo_component from "@/components/Invoice_components/InvoiceInfo_component.vue";
import MetodoPago_component from "@/components/Invoice_components/MetodoPago_component.vue";

export default {
  name: "SingularInvoice_view",
  components: {
    InvoicePDFViewer,
    InvoiceInfo_component,
    MetodoPago_component,
  },
  props: ["id"],
  data() {
    return {
      loading: true,
      invoice: false,
      metodo_pago: [
        {
          id: 1,
          nombre: "Paypal",
          datos: "4587",
        },
        {
          id: 2,
          nombre: "Stripe",
          datos: "4587",
        },
        {
          id: 3,
          nombre: "Redis",
          datos: "4587",
        },
      ],
    };
  },
  mounted() {
    this.getInvoiceData();
  },
  methods: {
    getInvoiceData() {
      const invoice_id = this.$route.params.invoice_id;
      this.$axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/api/portal/invoices/${invoice_id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authToken}`,
          },
        })
        .then((response) => {
          this.invoice = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>

<style scoped>
</style>
