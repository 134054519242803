<template>
  <div
  id="sideMenu"
    class="d-none d-md-flex d-xl-flex altura fondo-azul vh-100 p-2 vh-100 flex-column justify-content-between"
  >
    <img :src="universe_Image" alt="" id="logoUniversal" />

    <div class="d-flex p-2 pb-0 ms-5 sticky-login">
      <div class="col-md-1">
        <img :src="bottom_Logo" alt="" class="logoSide" />
      </div>
      <div class="col-md-10 d-flex flex-column whiteLogos justify-content-center">
        <div>infinity<strong>Sync</strong></div>
        <div>2024</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      // Definir las rutas de las imágenes
      universe_Image: require("@/assets/logos/iSync_universe.png"),
      bottom_Logo: require("@/assets/logos/white.png"),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whiteLogos {
  font-size: 1rem;
  color: white;
  margin: 0 0 0 10px;
}
.logoSide {
  width: 100%;
  max-height: 200px;
}
</style>