<template>
  <div class="p-3 cardProfile">
    <div class="text-center h4">Avisos</div>
    <div class="form-check form-switch d-flex align-items-center">
      <input
        class="form-check-input me-2"
        type="checkbox"
        role="switch"
        id="notificaciones"
        v-model="checked"
        name="notificaciones"
      />
      <label class="form-check-label" for="notificaciones"
        >Notificaciones</label
      >
    </div>

    <div class="form-check form-switch d-flex align-items-center">
      <input
        class="form-check-input me-2"
        type="checkbox"
        role="switch"
        id="notificacionesCorreo"
        v-model="checked"
        name="notificacionesCorreo"
      />
      <label class="form-check-label " for="notificacionesCorreo"
        >Notificaciones por correo</label
      >
    </div>

    <div class="form-check form-switch d-flex align-items-center">
      <input
        class="form-check-input me-2"
        type="checkbox"
        role="switch"
        id="notificacionCorreoEstado"
        v-model="checked"
        name="notificacionCorreoEstado"
      />
      <label class="form-check-label " for="notificacionCorreoEstado"
        >Notificarme por correo cuando cambie el estado</label
      >
    </div>

    <div class="form-check form-switch d-flex align-items-center">
      <input
        class="form-check-input me-2"
        type="checkbox"
        role="switch"
        id="notificacionCorreoStock"
        v-model="checked"
        name="notificacionCorreoStock"
      />
      <label class="form-check-label " for="notificacionCorreoStock">
        Notificarme por correo las alertas de stock
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Switch_component",
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<style>
</style>
