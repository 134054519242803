import { createStore } from "vuex";

export default createStore({
    state: {
        loading: true,
        showLogedPart: false, 
        showLoginPart: true, 
        error: {
            status: false,
            message: ''
        },
        version: '0.8',
        authToken: false,
        // credenciales en pantallas de login
        login: {
            email: '',
            password: '',
        },
        init: {
            company: false,
            companies: [],
            auth: false
            // // usuario autenticado
            // auth: false,
            // // empresa en caso de portal o empresa seleccionada
            // company: false,
            // // rol de usuario: contact, user
            // role: false
        },
        states: {

        },
        translations: {

        },


    },
    mutations: {
        setEmail(state, email) {
            state.login.email = email;
        },
        updateShowLogin(state, value) {
            state.showLoginPart = value;
        },
        updateShowLogged(state, value) {
            state.showLogedPart = value;
        },
    },
    actions: {
        guardarEmail({ commit }, newEmail) {
            commit('setEmail', newEmail);
        },
    },
    getters: {
        email(state) { //getter para recuperar el email
            return state.login.email
        }
    },
});