<template>
    <hr>

    <div class="fs-3 mt-3 mb-3">Métodos de pago disponibles</div>
    <div v-for="pago in metodo_pago" :key="pago.id" class="row mb-2">

      <div class="col-md-6 d-flex align-items-center">

        <font-awesome-icon :icon="['fas','credit-card']"  />
        <div class="ms-3">{{pago.nombre}}</div>
      </div>
      <div class="col-md-6">*** *** *** **{{pago.datos}}</div>
    </div>
</template>

<script>

export default {
  name: 'MetodoPago_component',
  props: {
    metodo_pago: {
      type: Array,
      required: true
    }
  }
}
</script>
