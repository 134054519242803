<template>
  <div class="p-3 cardProfile">
    <div class="text-center h4">Datos de usuario</div>

    <form action="" class="row">
      <div class="col-md-6">
        <div class="fs-5 mb-2 fw-bold">Acceso</div>

        <div class="mb-3">
          <label for="emailAcceso" class="form-label label_input"
            >Email address</label
          >
          <input
            type="email"
            class="form-control input_text"
            id="emailAcceso"
            aria-describedby="emailAcceso"
            v-model="usuario.personal.email"
          />
        </div>

        <div class="mb-3">
          <label for="nombreAcceso" class="form-label label_input"
            >Nombre personal</label
          >
          <input
            type="text"
            class="form-control input_text"
            id="nombreAcceso"
            aria-describedby="nombreAcceso"
            v-model="usuario.personal.nombrePersonal"
          />
        </div>

        <!-- principañ -->
        <div class="fs-5 mb-2 mt-4 fw-bold">Principal</div>
        <div class="mb-3">
          <label for="telefonoPrincipal" class="form-label label_input"
            >Teléfono</label
          >
          <input
            type="tel"
            class="form-control input_text"
            id="telefonoPrincipal"
            aria-describedby="telefonoPrincipal"
            v-model="usuario.principal.telf"
          />
        </div>

        <div class="mb-3">
          <label for="nombrePrincipal" class="form-label label_input"
            >Nombre personal</label
          >
          <input
            type="text"
            class="form-control input_text"
            id="nombrePrincipal"
            aria-describedby="nombrePrincipal"
            v-model="usuario.principal.nombrePersonal"
          />
        </div>

        <!-- color -->
        <div class="fs-5 mb-2 mt-4 fw-bold">Color</div>

        <div class="input-group">
          <input
            type="text"
            class="form-control input_text"
            disabled
            :value="usuario.color"
          />
          <div class="input-group-append">
            <input
              type="color"
              class="form-control form-control-color colorInout"
              id="colorInput"
              title="Choose your color"
              v-model="usuario.color"
            />
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="fs-5 mb-2 fw-bold">Logística</div>

        <div class="mb-3">
          <label for="emailLogistica" class="form-label label_input"
            >Nombre personal</label
          >
          <input
            type="email"
            class="form-control input_text"
            id="emailLogistica"
            aria-describedby="emailLogistica"
            v-model="usuario.logistica.email"
          />
        </div>

        <div class="mb-3">
          <label for="telfLogistica" class="form-label label_input"
            >Teléfono</label
          >
          <input
            type="tel"
            class="form-control input_text"
            id="telfLogistica"
            aria-describedby="telfLogistica"
            v-model="usuario.logistica.telf"
          />
        </div>

        <!-- administrador -->
        <div class="fs-5 mb-2 mt-4 fw-bold">Administración</div>

        <div class="mb-3">
          <label for="nombreAdministracion" class="form-label label_input"
            >Nombre personal</label
          >
          <input
            type="text"
            class="form-control input_text"
            id="nombreAdministracion"
            aria-describedby="nombreAdministracion"
            v-model="usuario.administracion.nombreEmpresa"
          />
        </div>

        <div class="mb-3">
          <label for="nifAdministracion" class="form-label label_input"
            >NIF/CIF</label
          >
          <input
            type="text"
            class="form-control input_text"
            id="nifAdministracion"
            aria-describedby="nifAdministracion"
            v-model="usuario.administracion.nif"
          />
        </div>

        <div class="mb-3">
          <label for="dirFiscalAdministracion" class="form-label label_input"
            >Dirección fiscal</label
          >
          <input
            type="text"
            class="form-control input_text"
            id="dirFiscalAdministracion"
            aria-describedby="dirFiscalAdministracion"
            v-model="usuario.administracion.direccionFiscal"
          />
        </div>

        <div class="mb-3">
          <label for="ibanAdministracion" class="form-label label_input"
            >IBAN</label
          >
          <input
            type="text"
            class="form-control input_text"
            id="ibanAdministracion"
            aria-describedby="ibanAdministracion"
            v-model="usuario.administracion.iban"
          />
        </div>

        <div class="mb-3">
          <label for="emailAdministracion" class="form-label label_input"
            >Email</label
          >
          <input
            type="email"
            class="form-control input_text"
            id="emailAdministracion"
            aria-describedby="emailAdministracion"
            v-model="usuario.administracion.email"
          />
        </div>

        <div class="mb-3">
          <label for="telfAdministracion" class="form-label label_input"
            >Teléfono</label
          >
          <input
            type="tel"
            class="form-control input_text"
            id="telfAdministracion"
            aria-describedby="telfAdministracion"
            v-model="usuario.administracion.telf"
          />
        </div>

        <input
          type="submit"
          value="Actualizar datos"
          class="btnSubmit btn btn-primary"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "PersonalInfo_component",
  data() {
    return {
      usuario: {
        personal: {
          email: "personal@example.com",
          nombrePersonal: "Juan Pérez",
        },
        logistica: {
          email: "logistica@example.com",
          telf: "123456789",
        },
        principal: {
          telf: "987654321",
          nombrePersonal: "Juan Pérez",
        },
        color: "#4285F5", // Por ejemplo, un color en formato hexadecimal
        administracion: {
          nombreEmpresa: "Ejemplo S.A.",
          nif: "A12345678",
          direccionFiscal: "Calle Ejemplo 123, Ciudad, País",
          iban: "ES9121000418450200051332",
          email: "administracion@example.com",
          telf: "1122334455",
        },
      },
    };
  },
};
</script>

<style>
.input_text {
  font-size: 0.8rem;
}
.label_input {
  font-size: 0.9rem;
}

.btnSubmit {
  margin-left: auto;
  display: block;
}
</style>
