<template>
    <div v-if="data == 'normal'" class="badge bg-blue-nice">Factura</div>
    <div v-if="data == 'recurring'" class="badge bg-blue-nice">Recurrente</div>
    <div v-if="data == 'rectification'" class="badge bg-danger">Rectificativa</div>

</template>

<script>

export default {
  name: 'Type_component',
  props: {
    data: {
      type: String,
      required: true
    }
  }
}
</script>
