<template>
    <div class="">

        <HeaderProfile_component></HeaderProfile_component>
        
        <div class="row">
            <div class="col-md-6 mt-4">
                <Switch_component class="mb-4"></Switch_component>
                <InvoiceList_component></InvoiceList_component>
            </div>
            <div class="col-md-6 mt-4">
                <PersonalInfo_component></PersonalInfo_component>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderProfile_component from "@/components/Profile_components/HeaderProfile_component.vue";
import PersonalInfo_component from "@/components/Profile_components/PersonalInfo_component.vue";
import InvoiceList_component from "@/components/Profile_components/InvoiceList_component.vue";
import Switch_component from "@/components/Profile_components/Switch_component.vue";

export default {
  name: "Profile_view",
  components: {
    HeaderProfile_component,
    PersonalInfo_component,
    InvoiceList_component,
    Switch_component,
  },
};
</script>

<style>
</style>
