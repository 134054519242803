<template>
  <div class="loginViews">
    <Logo_Form v-if="false"></Logo_Form>
    <Logo_Company/>


    <h4>Cargando... por favor espere. Estamos validando los credenciales... </h4>
    <Loading_component class="m-auto text-center mt -4" v-if="loading"  />

  </div>
</template>

<script>
//componentes externos
import Logo_Form from "@/components/auth/Logo_Form.vue"; 
import Logo_Company from "@/components/auth/Logo_Company.vue"; 

import cookie from "cookiejs";

export default {
  name: "Login_view_by_token",
  components: {
    Logo_Form,
    Logo_Company
  },

  data() {
    return {
      // Las variables que están ligadas a los inputs
      formulario_login: {
        email: this.$store.state.login.email,
        password: "",

      },
      error: null,
      loading: true,
    };
  },
  mounted() {
    // getvthe param of ther ur
    
    this.getTokenAndInitAppByToken()
  },
  methods: {
    getTokenAndInitAppByToken() {
      const id = this.$route.params.site_id;
      if(!id) {
        this.$router.push({ name: 'Login_view' });
        return;
      }
      const token = this.$route.query.token;
      if(!token) {
        this.$router.push({ name: 'Login_view' });
        return;
      }

       // //guardar el token enuna COOKIE
       cookie.set("authToken", token, {
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ),
        });
        console.log('token añadido a la cookie', token)
        // ir a la página de inicio por js
        window.location.href = "/"+id+"/login";
       
    },
  },
};
</script>

<style>
body {
  color: var(--letras-gris);
}

.altura {
  height: 100vh;
}

.link {
  text-decoration: none;
  color: var(--letras-gris);
}

.link:hover {
  color: var(--azul-fondo);
}

.icono-email {
  font-size: 1.5rem;
}

</style>