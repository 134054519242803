
<template>
    <table class="table-loading">
        <tbody>
            <tr v-for="index in rows" :key="index">
                <td class="td-1"><span></span></td>
                <td class="td-2"><span></span></td>
                <td class="td-3"><span></span></td>
                <td class="td-4"></td>
                <td class="td-5"><span></span></td>
            </tr>
        </tbody>
    </table>
</template>
            

<script>
export default {
    name: 'LoadingTable',
    props: {
        rows: {
            type: Number,
            default: 5
        },
        
    },
    data() {
        return {
            gif: require('@/assets/images/loading.gif')
        }
    }
}
</script>

<style scoped>

</style>