<template>
    Pagada
</template>

<script>

export default {
  name: 'Type_component',
  props: {
    data: {
      type: String,
      required: true
    }
  }
}
</script>
