<template>
  <div @click="logout" class="whiteLinks logout">
    <i class="bi bi-box-arrow-right "></i> Cerrar sesión
  </div>
</template>

<script>
import cookie from "cookiejs";
export default {
  name: "Logout_component",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async logout() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.$store.state.loading = true;

      //llamada a axios logout
      this.$axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/api/logout`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authToken}`,
          },
        })
        .then(() => {
          //borrar de la cookie
          this.deleteAfterSuccessResponse();
        })
        .catch((error) => {
          console.log("ERROR:", error.response.data.message);
          this.error = error.response.data.message;
        })
        .finally(() => {
          //se acaba la carga no importa cual es el resultado
          this.loading = false;
        });
    },
    deleteAfterSuccessResponse() {
      console.log(this.$store.state.init.role)
      if(this.$store.state.init.role == 'contact') {
        const hashed_id = this.$store.state.init.company.hashed_id;

        window.location.href = '/'+hashed_id+'/login';
        // esperar un segundo
        setTimeout(() => {
          //borrar de la cookie
          cookie.remove("authToken");
          //recargar
          window.location.reload();
        }, 1000);
      } else {
      //borrar de la cookie
      cookie.remove("authToken");
      //recargar
      window.location.reload();
      }
      
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.logout {
  cursor: pointer;
}
</style>