<template>
     
    <div class="loginViews">

            <Logo_Form></Logo_Form>


            <div > <!-- Ajusta el ancho total del formulario -->



                <div class="text-muted fs-5 mb-3 text-center">Escribe tu email para recibir un codigo de verificación
                </div>
                <form @submit="handleSubmit" class="mb-2">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Email" aria-label="Email"
                            aria-describedby="basic-addon1" v-model="formulario.email" required>
                    </div>

                    <input type="submit" value="Enviar" class="btn btn-primary w-100">
                </form>

                <router-link :to="{ name: 'Login_view' }" class="returnBackArrow">
                    <font-awesome-icon :icon="['fas', 'arrow-left-long']" /> Volver
                </router-link>
            </div>


    </div>




</template>

<script>
//componentes externos
import Logo_Form from '@/components/auth/Logo_Form.vue'; // Importa el componente

export default {
    name: 'Send_Email_view',
    components: {
        //aqui se indican los componentes externos que usamos
        Logo_Form
    },
    data() {
        return {
            // Las variables que están ligadas a los inputs
            formulario: {
                email: this.$store.state.login.email ,
            }
        }
    },
    methods: {
        handleSubmit() {
            console.log('Enviar email y tal. Una vez acabe lo enviamos a la vista de poner el code');
            //llamamos a la store, la accion de guardarEmail
            this.$store.dispatch('guardarEmail', this.formulario.email);

            //redirigimos a la vista de email_code_view
            this.$router.push({ name: 'Email_code_view' });
        }
    }
}
</script>

<style>
body {
    color: var(--letras-gris);
}

.altura {
    height: 100vh;
}

.link {
    text-decoration: none;
    color: var(--letras-gris);
}

.link:hover {
    color: var(--azul-fondo);
}
</style>