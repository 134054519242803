<template>
  <div :class="{ fullScreenLoading: fullScreen }">
    <!-- <img :src="gif" alt="Loading GIF" :style="{ width: width + 'px' }" class="loading-gif"> -->
    <div
      class="spinner-border"
      role="status"
      :style="{ width: width + 'px', height: width + 'px' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    width: {
      type: Number,
      default: 100,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gif: require("@/assets/images/loading.gif"),
    };
  },
};
</script>

<style scoped>
.loading-gif {
  width: 100px;
}
.fullScreenLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
</style>