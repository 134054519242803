<template>
    <!-- logo encima del formulario -->
    <div class="d-flex text-center flex-row align-items-center justify-content-center">
        <router-link to="/login">
            <img :src="logo" alt="" class="logoForm">
        </router-link>
        

        <div class=" text-start">
            <div class="fs-1">infinity<strong>Sync</strong></div>
            <div>Conecta. Sincroniza. Simplifica</div>
        </div>
    </div>
    <!-- FIN logo encima del formulario -->
</template>

<script>
export default {
    name: 'SideMenu',
    data() {
        return {
            // Definir las rutas de las imágenes
            logo: require('@/assets/logos/transparente.png'),

        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoForm {
    width: 100%;
    max-width: 140px;
    margin: 0 auto;
}
</style>