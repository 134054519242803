<template>
  
  <!-- logo -->
  <div class="align-items-center">
    <div class="text-center w-100">
      <router-link :to="{name: 'Home_view'}">
        <img
          width="100"
          class="img img-brand m-auto"
          :src="bottom_Logo"
          alt="Logo IninitySync"
        />
      </router-link>
      <span class="d-flex align-items-center justify-content-center logoText w-100">infinitySync</span >
      <!-- copirught -->
      <div v-if="showLegal" class="text-center text-grey smallLegal mb-2">
        <small>&copy; {{currentYear}} InfinitySync</small>
      </div>

    </div>
    
  </div>

</template>

<script>

export default {
  name: "Bottom-logo",
  props: {
    showLegal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bottom_Logo: require("@/assets/logos/transparente.png"),
      currentYear: new Date().getFullYear(),
      // Definir las rutas de las imágenes
      logo: require("@/assets/logos/white.png"),
    };
  },
  components: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.smallLegal {
  color: var(--azul-oscuro);
  font-size: 0.7rem;
}
.logoText {
  color: var(--azul-oscuro);
  font-size: 1.3rem;

}


</style>